import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import illustration from "../../../assets/images/appImages/illustration.png";
import photo from "../../../assets/images/appImages/founder.png";
import founder1 from "../../../assets/images/appImages/founder_1.png";
import founder2 from "../../../assets/images/appImages/founder_2.png";
import { TeamData } from "./data";
import { Link } from "react-router-dom";
import FooterPage from "../../auth/FooterPage";

const CustomBeautyTitle = ({ title }: { title: string }) => {
  return <h2 className="custom-esb-title">{title}</h2>;
};

function WhoWeAre() {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content who-we-are-page pb-0">
        <h1 className="custom-page-title">Qui sommes-nous ?</h1>
        <div className="who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="illustration-container">
                  <img src={illustration} alt="image" />
                </div>
              </div>
              <div className="col-md-6">
                <CustomBeautyTitle title="Qui sommes nous?" />
                <h3 className="custom-title">AwA Bio Foods</h3>
                <div className="custom-content">
                  Nous sommes des professionnels au service des consommateurs.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="plateform-founders">
          <div className="container">
            <CustomBeautyTitle title="Qui sommes nous" />
            <h3 className="custom-title">Notre vision</h3>
            <div className="custom-content">
              AwA Bio Foods est une entreprise qui s'est engagée pour le respect
              des exigences de sa Clientèle, raison pour laquelle on s'applique
              les normes les plus strictes en matière de Qualité, Hygiène
              Sécurité et Environnement. <br />
              <br />
              Notre vision est de développer des gammes de produits naturels et
              Bio pour répondre au besoin du marché, mais également contribuer
              au développement de la filière locale. Nos produits ont l'avantage
              de participer au maintien de la santé et du bien-être des
              consommateurs. <br />
              <br />
              Nous mettons nos Clients au centre de notre organisation et leur
              satisfaction est une priorité majeure. Pour le respect de notre
              écosystème nous faisons attention aux gaspillages des produits en
              recyclant le maximum de déchets en matières premières et
              emballages. <br />
              <br />
              <strong>
                Nous invitons tous nos partenaires dans ce combat hautement
                humain.
              </strong>
            </div>
          </div>
          <div className="dots_deco dots_deco__top"></div>
          <div className="dots_deco dots_deco__bottom"></div>
        </div>
        <div className="who-we-are">
          <div className="container">
            <CustomBeautyTitle title="Qui sommes nous" />
            <h3 className="custom-title">Mot de la fondatrice</h3>
            <div className="row pt-4">
              <div className="col-md-12 col-lg-12 col-wrapper">
                <div className="founder-card green_colgate__card">
                  <span className="photo-wrapper">
                    <img src={photo} alt="awa-bio-food-founder" />
                  </span>
                  <span className="name">Awa Mbengue</span>
                  <span className="description">
                    Tout d'abord, depuis tout petite j'ai toujours aimé la
                    nourriture saine et goûteuse ainsi que ses apports grâce à
                    ma mère... <br />
                    <br />
                    Au fil des années j'ai constaté nos habitudes
                    contradictoires avec une bonne hygiène alimentaire. Ensuite,
                    j'ai connue Dynapharm Sénégal via certains collègues. Cela a
                    plus consolidé mon idée sur les aliments naturels et bio.{" "}
                    <br />
                    <br />
                    A un moment donné, je devais prendre des initiatives et
                    participer activement aux grands changements qui nous
                    propulseront vers un avenir meilleur. <br />
                    <br />
                    Alors, un soir de mi-Sha'ban 1440 (20 Avril 2019) j'ai dit à
                    mes petits frères et sœurs qui étaient avec moi que je vais
                    créer mon entreprise. <br />
                    <br />
                    Le même jour j'ai choisi le nom, confectionné un logo
                    temporaire et j'ai demandé à plusieurs amis de nous suivre
                    sur les réseaux sociaux : AwA Bio Foods venait d'être
                    lancée.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
}

export default WhoWeAre;
