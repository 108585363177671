/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  cleannerError,
  getInTechTel,
  getInTechPhone,
  validatePhone,
  showModal,
} from "../../../../../utils/Utils";
import {
  OrderFormData,
  Order,
} from "../../../../../utils/api/order/order.type";
import {
  useAddOrEditOrderMutation,
  useAddOrEditOrderVisiteurMutation,
  useAddOrEditOrderNotUserMutation,
  useDeleteOrderMutation,
} from "../../../../../utils/api/order/order.api";
import { Color } from "../../../../../utils/theme";
import { FaTrash } from "react-icons/fa";
import { useDelete } from "../../../../../utils/helpers";
import { useAppSelector, useAppDispatch } from "../../../../../redux/hook";
import {
  ApiBaseUrl,
  IntechApikey,
  AppBaseUrl,
  Env,
} from "../../../../../utils/http";
import { removeCart } from "../../../../../redux/slice/useCartSlice";
import { useCheckStatusPaiement } from "../../../../../utils/Intech";
import { GoogleAdresseType } from "../../../../../utils/type";


function UseAddOrUpdateOrderForm(
  next: any,
  previous: any,
  panier?: any,
  total?: number,
  order?: Order
) {
  const { user } = useAppSelector((s) => s?.user);
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string | null>(null);
  const [mode, setMode] = useState<string>("");
  const [confirmePhone, setConfirmePhone] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [infosPayment, setInfosPayment] = useState<any>();
  const [level, setLevel] = useState<number>(0);
  const dispatch = useAppDispatch();
  const [adresse, setAdresse] = useState("");

  const validationSchema = yup.object().shape({
    step: yup.number().default(1),
    nom_client: yup.string().when("step", {
      is: 1,
      then: () => user ? yup.string().nullable().label("Le nom ") : yup.string().required().label("Le nom "),
    }),
    prenom_client: yup.string().when("step", {
      is: 1,
      then: () => user ? yup.string().nullable().label("Le prenom ") : yup.string().required().label("Le prenom "),
    }),
    email_client: yup.string().when("step", {
      is: 1,
      then: () => yup.string().email().nullable().label("L'email") ,
    }),
    telephone: yup.string().when("step", {
      is: 1,
      then: () => validatePhone(yup, code).required(),
    }),
    adresse: yup.string().when("step", {
      is: 1,
      then: () => yup.string().required().label("L'adresse"),
    }),
    mode_de_paiement: yup.string().when("step", {
      is: 2,
      then: () => yup.string().required().label("Le mode de paiement"),
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
  } = useForm<OrderFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [option, setOption] = useState<
    { label: string; value: string } | undefined
  >();
  const [modalConnexion, setModalConnexion] = useState(false);
  const navigate = useNavigate();

  const [addOrUpdateOrder, { isLoading, isSuccess, error, isError }] = user
    ? useAddOrEditOrderMutation()
    : useAddOrEditOrderNotUserMutation();
  const handleChangeIcone = (e) => {
    let files = e.target.files[0];
    //setValue("icone" , files)
  };

  const handleChangeBanniere = (e) => {
    let files = e.target.files[0];
    // setValue("banniere" , files)
  };

  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("telephone", "+"+item);
    } else {
      setValue("telephone", "");
    }
  };


  const onChangeAdresse = (val: GoogleAdresseType) => {
		setAdresse(val?.rue);
		if (val?.rue) {
			setValue(`adresse`, val?.rue);
		}
	};



  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  useEffect(() => {
    setPhone(user?.telephone);
  }, [user]);

  const onSubmitValid = async (data: OrderFormData) => {
    const res: any = await addOrUpdateOrder({ data: data });

    if ("data" in res) {
      let url = (res as any)?.data?.url;

      let amount: number | any = 1;
      if (Env == "prod") {
        amount = data?.montant_total;
      }

      if (data?.mode_de_paiement == "CASH") {
        dispatch(removeCart());
        Swal.fire({
          icon: "success",
          title: `Merci pour la confiance`,
          text: "Votre commande à bien été prise en compte. Veuillez consulter vos mails.",
          iconColor: Color.success,
          showConfirmButton: false,
          timer: 9000,
        }).then(() => {
          //closeModal("successModal");
          navigate("/", { replace: true });
        });
      } else {
        window.location.href = url;
      }
    }

    if ("error" in res) {
      setLoading(false);
      console.log("error", res?.error?.data?.message);
      // toast(, true);
      Swal.fire({
        icon: "error",
        title: res?.error?.data?.message
          ? res?.error?.data?.message
          : "Une erreur est survenue",
        showConfirmButton: false,
        timer: 5000,
      });
    }
};

  const onSubmit = async (data: OrderFormData) => {

    let code_commande: number | string = new Date().getTime();
    let apiCash = data?.mode_de_paiement;
    let phone:string = data?.telephone ? data?.telephone : "";

    data["page"] = "paiement";
    data["env"] = AppBaseUrl.slice(0, -1);

    setMode(apiCash);
    setLoading(true);

    if (data?.step && data?.step < 2) {
      setValue("step", 2);
      next();
    }else {
      Swal.fire({
        icon: "question",
        html:`Est ce que ce numero de téléphone est bien correcte : </br></br><h3><strong>`+ getInTechTel(phone) +`</strong></h3>`,
        title: `Attention !`,
        //text: "Est ce que ce numero de téléphone est bien correcte : "+data?.telephone ,
        iconColor: Color.themeColor,
        showConfirmButton: true,
        showDenyButton: true,
        denyButtonText: `Non, Modifier le téléphone`,
        confirmButtonText: "Oui, Valider mon panier",
        confirmButtonColor: Color.themeColor,
        denyButtonColor: "#aaa",

      }).then((result) => {

        
        if (result.isConfirmed) {
          
          onSubmitValid(data)

        } else if (result.isDenied) {
          setValue("step", 1);
          previous();
        }
      });
    }
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    option,
    Controller,
    control,
    handleChangeIcone,
    handleChangeBanniere,
    handleChangePhone,
    onChangeAdresse,
    adresse,
    phone,
  };
}

export function DeleteOrder({ item }: { item: Order }) {
  console.log("item", item);
  const [deleteItem, { isSuccess, isError, error }] = useDeleteOrderMutation();
  const onDelete = useDelete<Order>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "cette commande",
    successMessage: "Commande supprimé",
  });
  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  );
}

export default UseAddOrUpdateOrderForm;
